import CategoryModule from './categories/CategoryModule';
import UserModule from './users/UserModule';

type RouteDefintion = {
  icon?: JSX.Element | null;
  name: string;
  path: string;
  component: () => JSX.Element;
};

export const CMSRouteSections: RouteDefintion[] = [
  {
    icon: null,
    name: 'Users',
    path: 'users',
    component: UserModule,
  },
  {
    icon: null,
    name: 'Categories',
    path: 'categories',
    component: CategoryModule,
  },
];

export default CMSRouteSections;
