import { MediaS3UploadOptions, MediaSignature } from '../../types/Media';
import Api, { HttpStatusCode } from '../api/api';

const getSignature = async (acl: string, type: string) => {
  const params = { type, acl };

  const [response, bodyOrError] = await Api.get('media/signature', params);

  const { status, statusText } = response as Response;
  if (![HttpStatusCode.CREATED, HttpStatusCode.OK].includes(status)) {
    throw new Error(statusText);
  }
  return bodyOrError;
};

export const s3Upload = async (
  options: MediaS3UploadOptions,
): Promise<{
  fileName: string;
  url: string;
}> => {
  const { resource, type, acl, onProgress, totalUploadSize = 0 } = options;
  const { fields } = (await getSignature(acl, type)) as MediaSignature;
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    let bucketUrl = `http://${fields.bucket}.s3.localhost.localstack.cloud:4566/`;
    if (process.env.NODE_ENV !== 'development') {
      bucketUrl = `https://${fields.bucket}.s3.amazonaws.com`;
    }

    // eslint-disable-next-line no-template-curly-in-string
    const key = fields.key.replace('${filename}', options.filename);

    fd.append('Content-Type', fields['Content-Type']);
    fd.append('Policy', fields.Policy);
    fd.append('X-Amz-Algorithm', fields['X-Amz-Algorithm']);
    fd.append('X-Amz-Credential', fields['X-Amz-Credential']);
    fd.append('X-Amz-Date', fields['X-Amz-Date']);
    fd.append('X-Amz-Signature', fields['X-Amz-Signature']);
    fd.append('acl', fields.acl || 'public-read');
    fd.append('key', key);
    fd.append('success_action_status', '201');
    fd.append('file', resource);
    fd.append('Access-Control-Allow-Origin', '*');

    xhr.open('POST', bucketUrl, true);

    // Create strings
    xhr.onerror = () => reject(new Error('An error occurred during the transaction'));

    xhr.upload.onprogress = (e) => onProgress?.({ loaded: e.loaded, total: totalUploadSize });

    xhr.onreadystatechange = () => {
      if (xhr.readyState !== xhr.DONE) return;
      if (xhr.status !== HttpStatusCode.CREATED) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xhr.responseText, 'text/xml');
        return reject(xmlDoc.getElementsByTagName('Error')[0].childNodes[1].childNodes[0].nodeValue);
      }
      const uploadedURL = [bucketUrl, '/', key].join('');

      resolve({
        fileName: key,
        url: uploadedURL,
      });
    };

    xhr.send(fd);
  });
};
